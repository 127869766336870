import { graphql } from 'gatsby';
import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { ThemeContext } from '../components/theme-provider';
import SEO from '../components/seo';
import BlockContent from '../components/block-content';
import Box from '../components/box';
import SanityImage from 'gatsby-plugin-sanity-image';
import Video from '../components/video';
import LinkPanel from '../components/link-panel';
import Subscribe from '../components/subscribe';
import Job from '../components/job';
import Pill from '../components/pill';

export default function Careers({ data }) {
  const [positions, setPositions] = useState([]);

  const theme = useContext(ThemeContext);

  useLayoutEffect(() => {
    theme.setHeaderHide(false);
    theme.setLogoHide(false);
    theme.setLogoInvert(true);
    theme.setBackURL('/us');
  }, []);

  useEffect(() => {
    const fetchPositions = async () => {
      const response = await fetch('/api/positions');
      const positions = await response.json();

      setPositions(positions);
    };

    fetchPositions();
  }, []);

  return (
    <div
      css={css`
        background-color: var(--black);
      `}
    >
      <SEO
        title="Careers"
        description={
          data.careers._rawIntroText
            ? data.careers._rawIntroText
                .map((el) => el.children)
                .flat()
                .map((el) => el?.text)
                .join(' ')
            : null
        }
        imageID={
          data.careers.introMedia.type === 0 ? data.careers.introMedia.image.asset._id : null
        }
      />
      <div
        css={css`
          color: var(--white);

          a,
          a:visited {
            color: var(--white);
          }
        `}
      >
        <Box>
          {data.careers.introMedia.type === 0 && (
            <div
              css={css`
                img {
                  display: block;
                  width: 100%;
                  height: 100vh;
                  object-fit: cover;

                  @media (max-width: 800px) {
                    height: 105.4vw;
                    max-height: 100vh;
                  }

                  @media (max-width: 550px) {
                    height: 110vw;
                  }
                }
              `}
            >
              <SanityImage width={1980} {...data.careers.introMedia.image} />
            </div>
          )}
          {data.careers.introMedia.type === 1 && (
            <Video
              src={data.careers.introMedia.video.asset.url}
              autoPlay={true}
              muted={true}
              loop={true}
              css={css`
                width: 100%;
                height: 100vh;
                overflow: hidden;

                @media (max-width: 800px) {
                  height: 105.4vw;
                  max-height: 100vh;
                }

                @media (max-width: 550px) {
                  height: 110vw;
                  max-height: 100vh;
                }

                video {
                  height: 100vh;
                  object-fit: cover;

                  @media (max-width: 800px) {
                    height: 105.4vw;
                    max-height: 100vh;
                  }

                  @media (max-width: 550px) {
                    height: 110vw;
                  }
                }
              `}
            />
          )}
        </Box>
        <Box
          x={10}
          y={13}
          css={css`
            @media (max-width: 550px) {
              margin: var(--margin6) 0;
            }
          `}
        >
          <BlockContent 
            className="type--heading-four" 
            content={data.careers._rawIntroText} 
            css={css`
              @media (max-width: 550px) {
                font-size: 2.8rem !important;
                line-height: 1.21 !important;
                letter-spacing: -0.03rem !important;
              }
            `}
          />
        </Box>
        <Box 
          x={10} 
          y={18}
          css={css`
            @media (max-width: 550px) {
              margin: var(--margin5) 0;
            }
          `}
        >
          <h2
            className="type--heading-three"
            css={css`
              margin-bottom: var(--margin8);

              @media (max-width: 550px) {
                font-size: 2.8rem !important;
                line-height: 1.21 !important;
                letter-spacing: -0.03rem !important;
                margin-bottom: var(--margin3);
              }
            `}
          >
            Latest Jobs
          </h2>
          {positions.length > 0 && positions.map((position) => <Job
            location={position.location}
            title={position.name}
            info={position.type}
            url={`https://bvn.breezy.hr/p/${position.slug}`}
            invert={true}
          />)}
          <div
            css={css`
              text-align: center;
            `}
          >
            <Pill size={3} text="Visit Job Site" url="https://bvn.breezy.hr/" invert={true} />
          </div>
        </Box>
      </div>
      <Box
        css={css`
          background-color: var(--white);
        `}
      >
        <Subscribe />
      </Box>
      <LinkPanel
        hoverColor="var(--white)"
        invert={true}
        content={[
          {
            text: 'Studio',
            url: '/',
          },
          {
            text: 'Careers',
            url: '/',
          },
        ]}
      />
    </div>
  );
}

export const query = graphql`
  query {
    careers: sanityCareers(_id: { regex: "/(drafts\\\\.careers)?/" }) {
      introMedia {
        type
        image {
          ...Image
        }
        video {
          asset {
            url
          }
        }
      }
      _rawIntroText(resolveReferences: { maxDepth: 10 })
    }
  }
`;
